import styled from 'styled-components';

export const StyledUseful = styled.div`
    .useful {
        display: flex;
        gap: 20px;

        margin-bottom: 180px;
        cursor: pointer;

        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;

            border: 1px solid #b6b7ba;
            border-radius: 8px;
            text-align: center;
        }

        &__link {
            text-decoration: none;
        }

        &__image {
            width: 100%;
            height: auto;
            border-radius-top-left: 8px;
            border-radius-top-right: 8px;
        }

        &__text {
            height: 63px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            font-size: 18px;
            line-height: 24px;
            padding-bottom: 4px;
            
            & > span {
                font-weight: 500;
            }
        }
    }

    @media (max-width: 879px) {
        .useful {
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    @media (max-width: 768px) {
        .useful {
            &__text {
                font-size: 16px;
            }
        }
    }
`;

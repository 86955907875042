import React from 'react';
import Container from '../../componets/share/Container';
import Socials from '../../componets/share/Socials';
import YandexMap from '../../componets/contacts/YandexMap';
import ContactForm from '../../componets/contacts/ContactForm';
import { MainHeader } from '../../componets/mainHeader/mainHeader';
import { StyledContacts } from './Contacts.styled';
import { ContactsCard } from './contactsCard/contactsCard';
import { ReactComponent as Address } from '../../img/icons/contactsIcons/address.svg';
import { ReactComponent as CallUs } from '../../img/icons/contactsIcons/callUs.svg';
import { ReactComponent as TextUs } from '../../img/icons/contactsIcons/textUs.svg';

const Contacts = () => {
    const contactsData = [
        {
            title: 'Наш адрес',
            img: Address,
            content: 'г. Москва, МЖД Киевское 5-й км, д. 1, стр. 1, офис 3/3-17/10-WP',
            hrefTo: '#yandex-map',
        },
        {
            title: 'Позвонить нам',
            img: CallUs,
            content: '+7-495-14-41-495',
            hrefTo: 'tel:+74951441495',
        },
        {
            title: 'Написать нам',
            img: TextUs,
            content: 'info@doct24.ru',
            hrefTo: 'mailto: info@doct24.ru',
        },
    ];

    const cardList = contactsData.map((card) => {
        return (
            <ContactsCard key={card.title} title={card.title} content={card.content} hrefTo={card.hrefTo}>
                <card.img />
            </ContactsCard>
        );
    });

    return (
        <StyledContacts>
            <Container>
                {/* <MainHeader title={'Контакты'} /> */}
                {/* <div className="cardList">{cardList}</div> */}

                {/* <section className="where">
                    <h2 className="where__title">Где мы находимся</h2>
                    <YandexMap className="where__map" />
                </section> */}
                <section className="contact-us">
                    <h2 className="contact-us__title">Связаться с нами</h2>
                    <ContactForm />
                </section>
                <div className="social">
                    <p className="social__title">Вы можете связаться с нами через почту или социальные сети</p>
                    <Socials socials={['vk', 'odnok', 'telegram']} />
                </div>
            </Container>
        </StyledContacts>
    );
};
export default Contacts;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import star from '../../../../img/icons/star.png';
import { StyledCard } from './Card.styled';
import { useSelector } from 'react-redux';
import { getSeniorityWithEnd } from '../../../share/helpers';
import { Modal } from '../../../share/Modal';
import { Appointment } from '../../../ourDoctors/ourDoctorsModals/appointment/Appointment';

/**
 * @description Компонент для отображения карточки доктора
 * @param props Свойства компонента.
 * @param {Array} [props.specialization] Спецализации доктора, например - Стоматолог, Тепрапевт и др. Вида `[{id:number,name:string"}]`
 * @param {string} [props.topDoctorsId] UUID доктора который приходит с бекэнда. Подобного этому `02b90f88-0622-490c-b520-e6a123775fc8`
 * @param {string} [props.slug] Слаг доктора который приходит с бекэнда (Слаг имени доктора). Напрмиер `terapiia-daria-shcheglova`
 * @param {string} [props.avatar] Ссылка на аватар врача.
 * @param {string} [props.lastname] Фамилия врача.
 * @param {string} [props.name] Имя врача.
 * @param {string} [props.middlename] Отчество врача.
 * @param {number} [props.seniority] Опыт врача.
 * @param {number} [props.rating] Рейтинг врача.
 * @param {number} [props.price] Цена предоставления консультации.
 * @returns {Element}
 * @constructor
 */
const Card = (props) => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const role = useSelector((state) => state.authentification.whoAreYou);
    const isAuth = useSelector((state) => state.authentification.isAuth);

    const specialization = props.specialization?.length ? (
        props.specialization.map((spec) => {
            return <li key={spec.name}>{spec.name}</li>;
        })
    ) : (
        <li>Доктор не указал специализацию</li>
    );

    function showDoctor() {
        if (!isAuth) {
            setShowModal(true);
        } else {
            navigate(`/doctors/${props.topDoctorsId}/${props.slug}`);
        }
    }
    function showDoctorStopPropagation(e) {
        e.stopPropagation();
        showDoctor();
    }
    return (
        <StyledCard avatar={props.avatar} isFavorite={props.isFavorite} onClick={showDoctor}>
            <div className="card__img"></div>
            <div className="card__content">
                <div className="card__nameLike">
                    <p className="card__name">
                        {props.lastname} {props.name} {props.middlename}
                    </p>
                </div>
                <ul className="card__specialization">{specialization}</ul>
                <div>
                    <p className="card__experience">
                        Стаж {getSeniorityWithEnd(props.seniority)}
                        <span className="card__rating">
                            {props.rating > 0 && <img className="card__ratingImg" src={star} alt="rating" />}
                            {props.rating}
                        </span>
                    </p>
                    <p className="card__price">{props.price} &#8381;</p>
                    <button className="card__button" onClick={showDoctorStopPropagation}>
                        Записаться
                    </button>
                </div>
            </div>
            {showModal && (
                <Modal toggle={setShowModal}>
                    <Appointment closeModal={setShowModal} />
                </Modal>
            )}
        </StyledCard>
    );
};

export default Card;

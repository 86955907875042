import styled from 'styled-components';

export const FooterStyled = styled.footer`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 180px;
    background: #f4f4f4;
    width: 100%;

    .footer__top {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        justify-content: space-between;
        padding: 60px 0 40px;
        border-bottom: 1px solid #b6b7ba;
        height: auto;
        width: 100%;
    }

    .footer__logo {
        height: 47px;
        width: 147px;

        img {
            height: 47px;
            width: 147px;
            object-fit: cover;
        }
        a {
            display: inline-flex;
            cursor: pointer;
        }
    }

    .footer__block {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .footer__title {
            font-weight: 500;
            font-size: 24px;
            line-height: 130%;
            color: #212121;
        }

        .footer__link {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #212121;
            text-decoration: none;
            transition: color 0.3s ease-in-out, font-weight 0.3s ease-in-out;
            &:hover {
                font-weight: 500;
                transition: color 0.3s ease-in-out, font-weight 0.3s ease-in-out;
            }
            &:active {
                color: #19be6f;
            }
        }

        .footer__address {
            max-width: 245px;
        }
    }

    .footer__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 35px 0 52px;
        width: 100%;

        .footer__app-load {
            display: flex;
            gap: 24px;
        }

        .footer__text {
            max-width: 244px;
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;
            color: #212121;
        }
    }

    @media (max-width: 768px) {
        margin-top: 120px;
    }

    @media (max-width: 661px) {
        .footer__top {
            grid-template-columns: repeat(2, 1fr);
            row-gap: 24px;
        }

        .footer__bottom {
            flex-direction: column;
            gap: 20px;
            align-items: flex-start;
        }

        .footer__block {
            gap: 16px;
        }
    }

    @media (max-width: 400px) {
        margin-top: 80px;

        .footer__top {
            display: flex;
            flex-direction: column;
        }

        .footer__block {
            gap: 16px;
        }
    }
`;

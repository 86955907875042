import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './componets/header/header/header';
import Footer from './componets/footer/Footer';
import PushNotificationHandler from './features/pushNotifications/PushNotificationHandler';
import { useSelector } from 'react-redux';
import { useBodyPaddingRight } from "./hooks/useBodyPaddingRight";
import { useClearStorage } from "./hooks/useClearStorage";
import { AppStyled, Content } from "./App.styled";
// import Error from './pages/Error';

const App = () => {
    /** Хук для компенсации смещения body при скрытии/появлении скроллбара */
    useBodyPaddingRight();

    /** Хук для очистки sessionStorage при изменении пути. */
    useClearStorage();


    const { isAuth } = useSelector((state) => state.authentification);
    // const [isOnline, setIsOnline] = useState(navigator.onLine);
    // useEffect(() => {
    //     // console.log(isOnline)
    //     !isOnline && <Error/>
    // },[isOnline])

    // useEffect(() => {
    //     const handleStatusChange = () => {
    //       setIsOnline(navigator.onLine);
    //     };

    //     window.addEventListener('online', handleStatusChange);
    //     window.addEventListener('offline', handleStatusChange);

    //     return () => {
    //       window.removeEventListener('online', handleStatusChange);
    //       window.removeEventListener('offline', handleStatusChange);
    //     };
    // }, [isOnline]);

    return (
        <AppStyled>
            <Header />
            <Content>
                <Outlet />
            </Content>
            <Footer />
            {isAuth && <PushNotificationHandler />}
        </AppStyled>
    );
};

export default App;

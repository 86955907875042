import styled from 'styled-components';

export const RowArticleStyled = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: ${(props) => (props.fullSize ? 'row' : 'column')};
    max-width: ${(props) => (props.fullSize ? '775px' : '332px')};
    min-height: ${(props) => (props.fullSize ? '248px' : '360px')};
    border: 1px solid #f4f4f4;
    border-radius: 10px;
    overflow: hidden;
    transition: background-color 0.3s linear;

    &:hover {
        background-color: #f4f4f4;
    }

    .previewPictureBlog {
        position: relative;
        overflow: hidden;
        width: ${(props) => (props.fullSize ? '246px' : '100%')};
        @media (max-width: 768px) {
            min-width: 261px;
        }
        @media (max-width: 320px) {
            max-width: 288px;
            max-height: 190px;
        }
        img {
            position: absolute;
            max-width: 100%;
            min-height: 100%;
            object-fit: cover;
        }
    }

    .blogContent {
        line-height: 22px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;

        @media (max-width: 320px) {
            padding: 17px 20px 20px 14px;
        }
    }

    .textContent {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 22px;
        margin-bottom: 20px;

        @media (max-width: 768px) {
            gap: 14px;
            margin-bottom: 24px;
        }
    }

    .contentTitle {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        word-break: break-word;
    }

    .bottomContentInfo {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        row-gap: 8px;
        column-gap: 24px;
    }

    .infoBlock {
        display: flex;
        align-items: center;
        gap: 8px;
        white-space: nowrap;

        p {
            font-size: ${(props) => (props.fullSize ? '14px' : '12px')};
            font-style: normal;
            line-height: 150%;
        }

        svg path {
            stroke: #b6b7ba;
        }
        &:last-of-type svg path:not(:first-of-type) {
            fill: #b6b7ba;
        }
    }

    @media (max-width: 768px) {
        .contentTitle {
            font-size: ${(props) => (props.fullSize ? '20px' : '18px')};
        }
    }

    @media (max-width: 600px) {
        flex-direction: column;
        max-width: ${(props) => (props.fullSize ? '775px' : '100%')};
        .previewPictureBlog {
            height: 261px;
            width: 100%;
        }
        .blogContent {
            min-height: ${(props) => (props.fullSize ? '304px' : '155px')};
        }
        .contentTitle {
            font-size: ${(props) => (props.fullSize ? '18px' : '16px')};
        }
    }
`;

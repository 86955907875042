import { useLayoutEffect } from "react";
import { useScrollbarWidth } from "./useScrollbarWidth";

/**
 * Хук для компенсации смещения body и управления padding-right при скрытии/появлении скроллбара.
 * Предотвращает скачки контента при открытии модальных окон или изменении размеров страницы.
 */
export const useBodyPaddingRight = () => {
    const scrollbarWidth = useScrollbarWidth();

    useLayoutEffect(() => {
        const updatePadding = () => {
            const hasScrollbar = document.documentElement.clientHeight < document.documentElement.scrollHeight;
            const isScrollLocked = document.body.style.overflow === "hidden";

            requestAnimationFrame(() => {
                // Добавляем padding-right, если полоса прокрутки отсутствует или скрыта модальным окном
                document.body.style.paddingRight = hasScrollbar && !isScrollLocked ? "" : `${scrollbarWidth}px`;
            });
        };

        updatePadding();

        // Следим за изменениями в body, чтобы обновлять отступ при динамическом изменении контента
        const observer = new MutationObserver(updatePadding);
        observer.observe(document.body, { childList: true, subtree: true });

        // Следим за изменением размеров страницы
        const resizeObserver = new ResizeObserver(updatePadding);
        resizeObserver.observe(document.documentElement);

        return () => {
            observer.disconnect();
            resizeObserver.disconnect();
        };
    }, [scrollbarWidth]);
};


import { useLayoutEffect, useState } from "react";

/**
 * Хук для вычисления и отслеживания ширины скроллбара.
 */
export const useScrollbarWidth = () => {
    const [scrollbarWidth, setScrollbarWidth] = useState(0);

    useLayoutEffect(() => {
        const getScrollbarWidth = () => {
            const width = window.innerWidth - document.documentElement.clientWidth;
            // Проверяем, скрывает ли система полосу прокрутки (актуально для macOS)
            const isScrollbarHiddenBySystem = width === 0 && document.documentElement.scrollHeight > window.innerHeight;
            return isScrollbarHiddenBySystem ? 0 : width;
        };

        const updateScrollbarWidth = () => {
            requestAnimationFrame(() => {
                const width = getScrollbarWidth();
                setScrollbarWidth(width);
            });
        };

        updateScrollbarWidth();
        window.addEventListener("resize", updateScrollbarWidth);

        return () => window.removeEventListener("resize", updateScrollbarWidth);
    }, []);

    return scrollbarWidth;
};

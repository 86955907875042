import { useEffect } from 'react';

/**
 * Хук для блокировки прокрутки страницы.
 */
export const useLockBodyScroll = () => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        // Компенсации смещения реализована в хуке useBodyPaddingRight
        // document.body.style.paddingRight = `${scrollbarWidth}px`; 

        return () => {
            document.body.style.overflow = '';
            // document.body.style.paddingRight = '';
        };
    }, []);
};

import { SuccessConsultationStl } from './successConsultation.styled';
import { React } from 'react';
import { Button } from '../Button';
import successIcon from '../../../../img/icons/success-icon.svg';
import { useLockBodyScroll } from "../../../../hooks/useLockBodyScroll";

/** 
 SuccessConsultation - Компонент, который отображает сообщение об успешной оплате консультации.
Уведомляет пользователя о том, что детали оплаты были отправлены на его почту.

@component
@param {Object} props - Свойства компонента.
@param {Function} props.closeModal - Функция для закрытия модального окна.

@returns {JSX.Element} Возвращает JSX элемент, представляющий состояние успешной оплаты.

@example
return (
<SuccessConsultation closeModal={someCloseModalFunction} />
);

Компонент включает в себя следующие элементы:
- Иконка успешного завершения для визуального представления информации.
- Заголовок с текстом об успешной отправке деталей оплаты.
- Текстовое описание с информацией о сроках оплаты и действиях, если письмо не пришло.
- Кнопка для закрытия модального окна.
*/

export const SuccessConsultation = ({ closeModal, email }) => {
    useLockBodyScroll();

    return (
        <SuccessConsultationStl>
            <div className="success">
                <img src={successIcon} alt="success" className="success__icon" />
                <h3 className="success__title">Детали оплаты отправлены на почту</h3>
                <div className="success__content">
                    <span>
                        Информация об оплате была отправлена на Вашу почту {email}. Оплатить консультацию можно в
                        течение 24 часов. Если оплата не будет произведена, бронь на консультацию будет аннулирована.
                        Если письма нет, проверьте папку «Спам».
                    </span>
                </div>
                <div className="success__btn">
                    <Button className="success__btn-repeat" onClick={closeModal}>
                        Понятно
                    </Button>
                </div>
            </div>
        </SuccessConsultationStl>
    );
};

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Хук для очистки sessionStorage при изменении пути.
 */
export const useClearStorage = () => {
    const location = useLocation();

    useEffect(() => {
        if (!location.pathname.includes('/doctors')) {
            sessionStorage.removeItem('filters');
            sessionStorage.removeItem('pagesLoaded');
            sessionStorage.removeItem('lastVisitedDoctorId');
        }
    }, [location.pathname]);
};

import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledDoctorConsultationCard } from './doctorConsultationCard.styled';
import { consultationStatus } from '../lkDoctorConsultationHelpers';
import { ConsultationButtons } from '../../consultationButtons/consultationButtons';
import { ConsultationTimeAndPrice } from '../../../../share/consultationTime/consultationTime';
import AvatarDefault from '../../../../../img/avatar_default.png';
import { useDispatch } from 'react-redux';

/**
 * Компонент отображает карточку консультации врача с информацией о пациенте,
 * времени и цене консультации, а также кнопками действий.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.consultation - Данные о консультации.
 * @param {string} props.consultation.status - Статус консультации.
 * @param {string} props.consultation.consultation_time - Время консультации.
 * @param {Object} props.consultation.patient - Информация о пациенте.
 * @param {Object} props.consultation.patient.user - Данные пользователя пациента.
 * @param {string} props.consultation.patient.user.id - ID пациента.
 * @param {string} props.consultation.patient.user.last_name - Фамилия пациента.
 * @param {string} props.consultation.patient.user.first_name - Имя пациента.
 * @param {string} [props.consultation.patient.user.middle_name] - Отчество пациента (если есть).
 * @param {string} [props.consultation.patient.user.image_standart] - URL изображения пациента.
 * @param {string} props.consultation.patient.user.date_birth - Дата рождения пациента.
 * @param {string} props.consultation.patient.user.sex - Пол пациента.
 * @param {Object} props.consultation.doctor - Информация о враче.
 * @param {number} props.consultation.doctor.price - Цена консультации.
 * @param {string} props.consultation.id - ID консультации.
 * @param {string} [props.consultation.registration_log.cancelled_by.uid] - ID пользователя, отменившего консультацию.
 * @param {string} [props.consultation.patient_notes] - Примечания пациента.
 * @param {string} [props.consultation.recommendation] - Рекомендации врача.
 * @param {boolean} props.consultation.is_payed - Флаг, показывающий, оплачена ли консультация.
 *
 * @returns {JSX.Element} JSX элемент, представляющий карточку консультации.
 */

export const DoctorConsultationCard = forwardRef(({ consultation }, ref) => {
    const navigate = useNavigate();

    const {
        status,
        consultation_time,
        patient: {
            user: { id: patientUserId, last_name, first_name, middle_name, image_standart },
        },
        doctor: {
            price,
            user: { id: doctorId },
        },
        id: consultationID,
        registration_log,
        is_payed,
    } = consultation;

    const fullName = `${last_name ? last_name : 'Пациент'} ${first_name ? first_name : ''} ${
        middle_name ? middle_name : ''
    }`;

    const avatar = image_standart ? image_standart : AvatarDefault;

    const isPatientCancelled = doctorId !== registration_log?.cancelled_by;

    const showMoreConsultation = () => {
        navigate(`${consultationID}`);
    };

    return (
        <StyledDoctorConsultationCard onClick={showMoreConsultation} ref={ref}>
            <div className="photoBlock">
                <img src={avatar} alt="photo" />
            </div>
            <div className="status">{consultationStatus(status, is_payed, isPatientCancelled)}</div>

            <div className="info patientName">{fullName}</div>
            <div className="details">
                <ConsultationTimeAndPrice card={true} date={consultation_time} price={price} />
                <ConsultationButtons className="btns" consultation={consultation} />
            </div>
        </StyledDoctorConsultationCard>
    );
});

// Добавляем displayName для улучшения читаемости предупреждений и дебаггинга
DoctorConsultationCard.displayName = 'DoctorConsultationCard';
